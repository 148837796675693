import React from "react";
import LayoutWrapper from "../../component/LayoutWrapper/Index";
import BlogsPosts from "../../component/Blog/BlogPost/index";
import { StaticImage } from "gatsby-plugin-image";
import { Link } from "gatsby";
import ShareLinks from "../../component/Blog/ShareLinks";
import AuthorAvatar from "../../component/Blog/AuthorAvatar";
import CommonParagraph from "../../component/Blog/CommonParagraph";
import Heading from "../../component/Blog/Heading";
import BlogLink from "../../component/Blog/BlogLink";
import { blogList } from "../../data/blogs";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import ContactSlideoutLink from "../../component/Blog/ContactSlideoutLink";

const BlogPost = () => {
  const blogData = blogList.filter(
    (blog) => blog.url == "/real-estate-investment-opportunities-in-hillcrest"
  );

  return (
    <LayoutWrapper
      title={blogData[0].title}
      desc={blogData[0].metaDescription}
      headerLogo="../../images/Selby_logo_v2.svg"
      headerId="header_v2"
    >
      <div className="pt-[130px] pb-[80px] md:pt-[170px] md:pb-[70px] sm:pt-[30px] 2xs_extar:pb-[50px]">
        <div className="container">
          <div className="max-w-[800px] w-full mx-[auto]">
            <h1 className="text-d-3xl text-[#020101] font-IbarraRealNova font-[600] mb-[32px] sm:text-d-xl sm:mb-[]">
              {blogData[0].title}
            </h1>
          </div>
          <div className="flex justify-between items-center max-w-[800px] w-full mx-[auto] mb-[40px] 2xs_extar:flex-col  2xs_extar:justify-start 2xs_extar:items-start 2xs_extar:gap-[20px]">
            <div className="flex gap-[14px]">
              <AuthorAvatar />
              <div className="">
                <a
                  href={blogData[0].authorUrl && blogData[0].authorUrl}
                  className="font-Quicksand text-[#1A1A1A] text-d-2lg font-[600] mb-[4px]"
                >
                  By {blogData[0].author}
                </a>
                <p className="font-Quicksand text-d-2lg font-[400] text-[#666666]">
                  {blogData[0].date}
                </p>
              </div>
            </div>
            <div className="flex gap-[24px]">
              <ShareLinks url={`https://selbysellssd.com${blogData[0].url}`} />
            </div>
          </div>

          <div className="">
            <StaticImage
              src="../../images/The Selby Team_Real Estate Investment Opportunities In Hillcrest.jpg"
              alt="blogpost"
            />
            <div className="max-w-[800px] w-full mx-[auto] py-[80px] 2xs_extar:py-[30px]">
              <CommonParagraph>
                Thinking about your next real estate investment move? San Diego’s uptown
                neighborhood, Hillcrest, is definitely worth exploring.
              </CommonParagraph>
              <CommonParagraph>
                While this community does require substantial upfront investment capital, high
                occupancy rates, new development projects, and an inclusive environment make this an
                appealing place for renters and investors alike
              </CommonParagraph>
              <CommonParagraph>
                But should you invest here? We’ll give you an overview of the situation.{" "}
              </CommonParagraph>
              <Heading type="h2">What Investors Need to Know About Hillcrest</Heading>
              <CommonParagraph>
                If you’re wondering why Hillcrest is a hotspot for real estate investors, consider
                the following:
              </CommonParagraph>
              <Heading type="h3">Location</Heading>
              <CommonParagraph>
                Hillcrest is only a stone's throw away from major freeways, the waterfront, and
                other amenities. These include sprawling Balboa Park, known for its 1,200 open acres
                and proximity to museums and theaters, Mission Beach, La Jolla Cove, Mount Laguna,
                and Cuyamaca Rancho State Park.
              </CommonParagraph>
              <Heading type="h3">Community</Heading>
              <CommonParagraph>
                Hillcrest is best known as an LGBTQ- friendly community, but it also attracts a nice
                mix of residents, including young professionals, families, artists, and even
                retirees.
              </CommonParagraph>
              <CommonParagraph>
                And because it's relatively small and walkable, residents do not have to rely on
                cars to access the numerous bars and restaurants that dot the tree-lined streets.
                These features make the Hillcrest community incredibly appealing to
                renters—especially those who either prefer to rent or cannot afford the cost of
                entry to purchase a home.
              </CommonParagraph>
              <Heading type="h3">Rental Market</Heading>
              <CommonParagraph>
                The rental market is competitive but offers a solid return on investment for
                investors who can afford the cost of entry. Here are some numbers to help you put
                the possibilities into perspective. As of this writing:
              </CommonParagraph>
              <Heading type="h4">Apartments</Heading>
              <CommonParagraph>
                <ul>
                  <li>
                    Median rent was $2,648/month for an{" "}
                    <BlogLink
                      url="https://www.zumper.com/rent-research/san-diego-ca/hillcrest"
                      text="apartment"
                    />
                  </li>
                  <li>A one-bedroom apartment typically costs $2,506/month</li>
                  <li>A two-bedroom apartment costs roughly $3,500/month</li>
                  <li>The vacancy rate was about .7% lower than the rest of San Diego</li>
                </ul>
              </CommonParagraph>
              <Heading type="h4">Houses</Heading>
              <CommonParagraph>
                Pricing the rental market on houses is a little trickier, but here are the numbers
                we’re seeing: Median rent for a{" "}
                <BlogLink
                  url="https://www.zumper.com/rent-research/san-diego-ca/hillcrest"
                  text="single fmily home"
                />{" "}
                is currently $3,200. However, rent can vary depending on the location, size, and
                more.
              </CommonParagraph>
              <Heading type="h3">Development</Heading>
              <CommonParagraph>
                While the housing market is competitive, real estate development projects like The
                Hillcrest Gateway are opening up even more possibilities for investors. While this
                project is still in development, here’s what we know:
              </CommonParagraph>
              <CommonParagraph>
                Developers submitted a plan application in 2022 detailing the specifics of the
                project. In addition to including parks and pedestrian plazas, developers proposed
                several buildings up to 14 stories tall to accommodate residential units and more
                affordable housing options for residents. This project is still undergoing review,
                but this is one worth keeping an eye on.
              </CommonParagraph>
              <Heading type="h3">Cost of Entry</Heading>
              <CommonParagraph>
                Currently, the median home value in Hillcrest is around $799,000. To put that number
                in perspective, let’s compare it with other moderate and luxury-end communities in
                the San Diego area:
              </CommonParagraph>
              <Heading type="h4">Lower-Priced Options:</Heading>
              <CommonParagraph>
                <ul>
                  <li>
                    <BlogLink
                      url="https://www.redfin.com/neighborhood/547396/CA/San-Diego/City-Heights/housing-market"
                      text="City Heights"
                    />
                    : $620,500
                  </li>
                  <li>
                    <BlogLink
                      url="https://www.redfin.com/neighborhood/6455/CA/Chula-Vista/Eastlake-Vistas/housing-market"
                      text="East Lake"
                    />
                    : $653,000
                  </li>
                  <li>
                    <BlogLink
                      url="https://www.redfin.com/neighborhood/2056/CA/Chula-Vista/Otay-Ranch/housing-market"
                      text="Otay Ranch"
                    />
                    : $785,000
                  </li>
                </ul>
              </CommonParagraph>
              <Heading type="h4">Moderately Priced Options:</Heading>
              <CommonParagraph>
                <ul>
                  <li>
                    <BlogLink
                      url="https://www.redfin.com/neighborhood/1844/CA/San-Diego/Normal-Heights/housing-market"
                      text="Normal Heights"
                    />
                    : $865,000
                  </li>
                  <li>
                    <BlogLink
                      url="https://www.redfin.com/neighborhood/1924/CA/San-Diego/North-Park/housing-market"
                      text="North Park"
                    />
                    : $905,000
                  </li>
                  <li>
                    <BlogLink
                      url="https://www.realtor.com/realestateandhomes-search/Clairemont-Mesa-West_San-Diego_CA/overview"
                      text="Clairemont"
                    />
                    : $1,100,000
                  </li>
                </ul>
              </CommonParagraph>
              <Heading type="h4">High-End Options:</Heading>
              <CommonParagraph>
                <ul>
                  <li>
                    <BlogLink
                      url="https://www.redfin.com/neighborhood/1445/CA/San-Diego/La-Jolla/housing-market"
                      text="La Jolla"
                    />
                    : $2,225,000
                  </li>
                  <li>
                    <BlogLink
                      url="https://www.realtor.com/realestateandhomes-search/Coronado_CA/overview"
                      text="Coronado"
                    />
                    : $2,900,000
                  </li>
                  <li>
                    <BlogLink
                      url="https://www.redfin.com/city/25161/CA/Rancho-Santa-Fe/housing-market"
                      text="Rancho Santa Fe"
                    />
                    : $4,200,000
                  </li>
                </ul>
              </CommonParagraph>
              <CommonParagraph>
                Keep in mind that these numbers are subject to change, but as of this blog’s
                writing, this is what the San Diego market looks like.
              </CommonParagraph>
              <Heading type="h2">Find Your Dream Home With a Selby Realtor</Heading>
              <CommonParagraph>
                If you’re interested in exploring investment opportunities in the Hillcrest
                community, our experienced agents can help. From their market knowledge and
                negotiation expertise to their tailored searches, streamlined processes, and
                professional network, our team has the experience and skills necessary to help you
                find your ideal investment property. <ContactSlideoutLink text="Contact us today" />
                !
              </CommonParagraph>
            </div>
            <div className="">
              <div className="flex justify-center gap-[24px]">
                <ShareLinks url={`https://selbysellssd.com${blogData[0].url}`} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </LayoutWrapper>
  );
};

export default BlogPost;
